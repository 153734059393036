import { ChangeDetectionStrategy, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { UserFormService } from '../../user-form/user-form.service';
import { checkSamePassword, passwordValidator } from '../../util/validators';
import { updatePassword } from '../dn-dialog.actions';
import { DottnetDialogComponent } from '../dn-dialog.component';
import { PasswordChange } from '../../../domain/user/user.model';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';

export interface ChangePasswordDialogData {
	idAnagrafica: number;
}

export interface ChangePasswordForm {
	oldPassword: FormControl<string | null>;
	newPassword: FormControl<string | null>;
	confirmNewPassword: FormControl<string | null>;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'dottnet-mat-dialog-change-password',
    templateUrl: 'change-password-dialog.component.html',
    styleUrls: ['change-password-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatButtonModule, FontAwesomeModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, TranslateModule]
})
export class ChangePasswordDialogComponent
	extends DottnetDialogComponent<ChangePasswordDialogComponent>
	implements OnInit {
	idAnagrafica: number;
	changePwdForm: FormGroup<ChangePasswordForm>;

	confirmPasswordDisabled: boolean = true;

	get newPassword() {
		return this.changePwdForm?.get('newPassword');
	}

	get confirmNewPassword() {
		return this.changePwdForm?.get('confirmNewPassword');
	}

	constructor(
		dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private changePasswordData: ChangePasswordDialogData,
		@Inject(PLATFORM_ID) platformId: Object,
		store: Store,
		private fb: FormBuilder,
		private formService: UserFormService
	) {
		super(dialogRef, undefined, store, platformId);
	}

	ngOnInit(): void {
		this.changePwdForm = this.fb.group<ChangePasswordForm>(
			{
				oldPassword: new FormControl('', { validators: [Validators.required] }),
				newPassword: new FormControl('', {
					validators: [Validators.required, passwordValidator()]
				}),
				confirmNewPassword: new FormControl(
					{ value: '', disabled: true },
					{ validators: [Validators.required] }
				)
			},
			{ validators: checkSamePassword() }
		);
	}

	updatePassword() {
		const passwordChange: PasswordChange = {
			userId: this.changePasswordData.idAnagrafica,
			oldPwd: this.changePwdForm.get('oldPassword').value,
			newPwd: this.changePwdForm.get('newPassword').value			
		}
		this.store.dispatch(
			updatePassword({
				passwordChange: passwordChange})
		);
	}

	errorMessage(field: string): string {
		return this.formService.buildErrorMessage<ChangePasswordForm>(this.changePwdForm, field);
	}

	prova() {
		if (this.newPassword?.errors?.length === 0 || !this.newPassword?.errors) {
			this.confirmNewPassword.enable();
		} else {
			this.confirmNewPassword.disable();
		}
	}
}
